<template>
    <div class="row grow w-100">
        <div class="col-1">
            <NavSidebar />
        </div>
        <div class="main col-11 h-100 py-5 padding-subpages">
            <b-card style="border: none">
                <b-card-title class="px-3 py-3">Datenschutz</b-card-title>
                <b-card-body>
                    <h5>1. Verantwortliche Stelle</h5>
                    <p>Hochschule der Medien | Rektor: Alexander W. Roos<br>
                        Institut für Digitale Ethik | Prof. Dr. Petra Grimm, Prof. Dr. Oliver Zöllner<br>
                        Nobelstraße 10<br>
                        70569 Stuttgart<br>
                        <a href="https://www.hdm-stuttgart.de">www.hdm-stuttgart.de</a>
                    </p>

                    <h5>2. Datenschutzbeauftragte</h5>
                    <p>Bei Ersuchen auf Auskunft, Berichtigung, Löschung etc. (Art. 15 ff. DS-GVO, s.u. Ziff. 9) wenden Sie
                        sich bitte an: <a href="mailto:datenauskunft@hdm-stuttgart.de">datenauskunft@hdm-stuttgart.de</a>
                    </p>
                    <p>
                        Bei sonstigen Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten
                        kontaktieren Sie bitte die Datenschutzbeauftragte der HdM:
                    </p>
                    <p>
                        Lisa Lingner<br>
                        Telefon: 0711/ 8923 - 2057<br>
                        E-Mail: <a href="mailto:lingner@hdm-stuttgart.de">lingner@hdm-stuttgart.de</a>
                    </p>

                    <h5>3. Geltungsbereich</h5>
                    <p>Nutzende erhalten mit dieser Datenschutzerklärung Information über Art, Umfang und Zweck der Erhebung
                        und Verwendung ihrer Daten durch den verantwortlichen Betreiber dieser Webseite bzw. des ELSI-SAT
                        H&C-Tools (s.o.). Den gesetzlichen Rahmen hierfür bilden insbesondere die
                        Datenschutz-Grundverordnung (DS-GVO), das Bundesdatenschutzgesetz (BDSG) sowie das
                        Landesdatenschutzgesetz für Baden-Württemberg (LDSG BW).
                    </p>
                    <p>
                        Einzelne Betroffenenrechte oder Verpflichtungen des Datenverantwortlichen können dabei durch das
                        <b>Forschungsprivileg</b> (Art. 89 Abs. 2 DS-GVO i.V.m. § 27 BDSG, § 13 LDSG BW) eingeschränkt sein.
                    </p>

                    <h5>4. Tool- und Webseitennutzung</h5>
                    <p>Das ELSI-SAT H&C-Tool kann ohne Angabe personenbezogener Daten heruntergeladen (pdf-Datei) bzw.
                        bearbeitet (json-Datei) werden. Mit dem Zugriff auf diese Webseite werden jedoch automatisch
                        Informationen von Nutzenden erfasst.
                    </p>
                    <p>
                        Diese Informationen (Server-Logfiles) sind rein technischer Natur und erlauben ohne weitere
                        Maßnahmen keine Rückschlüsse auf einzelne Personen. Erfasst werden dabei insbesondere: Name der
                        Webseite, Datei, Datum, Datenmenge, Webbrowser und Webbrowser-Versionen, Betriebssystem, Domainname
                        Ihres Internet-Providers, Referrer-URL (jene Seite, von der aus auf das Angebot zugegriffen wird)
                        sowie Ihre IP-Adresse. Ohne diese Daten wäre es nicht, jedenfalls nicht vorbehaltlos möglich, die
                        Inhalte der Webseite technisch ordnungsgemäß zu übermitteln und fehlerfrei darzustellen. Wir
                        verarbeiten und speichern - wenn möglich - nur anonymisierte Daten und Informationen, z.B. für
                        statistische Zwecke.
                    </p>
                    <p>
                        Rechtsgrundlage für diese Datenerhebungen ist Art. 6 Abs. 1 lit. e DS-GVO (bzw. Abs. 2 i.V.m. § 27
                        BDSG bzw. § 13 LDSG BW).
                    </p>

                    <h5>5. Cookies</h5>
                    <p>Diese Webseite verwendet sogenannte Cookies, also Textdateien, die vom Server auf Ihrem Rechner
                        gespeichert werden. Sie generieren Informationen zum Browser, der IP-Adresse, dem Betriebssystem und
                        zur Internetverbindung (sog. First Party-Cookies). Sie sollen helfen die Navigation durch das
                        Angebot zu erleichtern und ermöglichen die korrekte Darstellung dieser Webseite. Es handelt sich
                        daher ausschließlich um technisch notwendige Cookies.
                    </p>
                    <p>
                        Diese Daten werden weder an Dritte weitergegeben noch mit (sonstigen) personenbezogenen Daten
                        verknüpft. Nutzende haben die Möglichkeit, das Angebot auch ohne derartige Cookies abzurufen.
                        Hierfür müssen in deren Browser entsprechende Einstellungen geändert werden. Informieren Sie sich
                        diesbezüglich über die Konfigurationen Ihres Browsers zur Speicherung, Deaktivierung bzw. Löschung
                        von Cookies. Insofern müssen wir allerdings darauf hinweisen, dass dadurch einige Funktionen dieser
                        Webseite bzw. des Tools beeinträchtigt sein könnten.
                    </p>
                    <p>
                        Rechtsgrundlage für vorgenannte Datenverarbeitung ist ebenfalls Art. 6 Abs. 1 lit. e DS-GVO (bzw.
                        Abs. 2 i.V.m. § 27 BDSG bzw. § 13 LDSG BW).
                    </p>

                    <h5>6. Dienste und Inhalte Dritter</h5>
                    <p>Das ELSI-SAT H&C-Tool verweist mitunter auf Inhalte oder Dienste anderer Anbieter (hier: sog.
                        Drittanbieter). Damit diese Daten im Browser von Nutzenden aufgerufen und dargestellt werden können,
                        ist z.B. die Übermittlung der betreffenden IP-Adressen notwendig. Insofern wird darum gebeten, sich
                        anhand der jeweiligen Datenschutz- und Privacy-Erklärungen über die (Weiter-)Verarbeitung Ihrer
                        Daten bei dem jeweiligen Drittanbieter selbst zu informieren.
                    </p>
                    <p>
                        Rechtsgrundlage ist auch hier Art. 6 Abs. 1 lit. e DS-GVO (bzw. Abs. 2 i.V.m. § 27 BDSG bzw. § 13
                        LDSG BW).
                    </p>

                    <h5>7. Anfragen und Kommunikation</h5>
                    <p>Wenn Sie uns per Onlineformular oder E-Mail mit personenbezogenen Daten kontaktieren, speichern wir
                        die dort von Ihnen gemachten Angaben schon deshalb, damit Ihre Anfrage(n) beantwortet und mögliche
                        Anschlussfragen geklärt werden können. Diese Daten werden gelöscht, sobald deren Speicherung nicht
                        mehr notwendig ist oder nicht mehr angemessen erscheint (weil z.B. die Anfrage beantwortet wurde,
                        ein sonstiger Anlass für den Kontakt weggefallen ist und/oder gesetzliche Aufbewahrungspflichten
                        nicht mehr fortbestehen).
                    </p>
                    <p>
                        Die Rechtsgrundlage für diese Datenverarbeitungen ist ebenfalls Art. 6 Abs. 1 lit. e DS-GVO (bzw.
                        Abs. 2 i.V.m. § 27 BDSG bzw. § 13 LDSG BW).

                    </p>

                    <h5>8. (Sonstige) Speicherdauer</h5>
                    <p>Personenbezogene Daten verarbeiten und speichern wir nach den Grundsätzen der DS-GVO (insbesondere:
                        „Datenminimierung“ gem. Art. 5 Abs. 1 lit. c sowie „Speicherbegrenzung“ gem. Art. 5 Abs. 1 lit. e
                        DS-GVO) nur solange, wie es für vorgenannte Verarbeitungszwecke (insbesondere: Forschungs- und/oder
                        Archivierungszwecke, Nachweiszwecke) erforderlich erscheint oder vom Gesetzgeber vorgeschrieben ist.
                        Insofern kann das <b>Forschungsprivileg</b> greifen (z.B. bei Zweckänderungen, vgl. Art. 5 Abs. 1
                        lit. b
                        i.V.m. Art. 6 Abs. 4 DS-GVO).
                    </p>
                    <p>
                        Sobald diese Zwecke entfallen und eine gesetzliche Verjährungs- oder Aufbewahrungsfrist endet oder
                        nicht besteht, werden die Daten möglichst umgehend gelöscht.
                    </p>

                    <h5>9. Betroffenenrechte </h5>
                    <p>Sie können auf Antrag unentgeltlich Auskunft über Ihre gespeicherten personenbezogenen Daten,
                        Berichtigung, Löschung oder Einschränkung der Verarbeitung (Art. 15 ff. DS-GVO) verlangen. Im Fall
                        einer Einwilligung haben Sie ein Widerrufsrecht (Art. 7 Abs. 3 DS-GVO). Beruht die Datenerhebung auf
                        einem öffentlichen Interesse (vgl. Art. 6 Abs. 1 lit. e DS-GVO) besteht ein Recht auf Widerspruch
                        (Art. 21 DS-GVO).
                    </p>
                    <p>
                        Diese Betroffenenrechte können im Einzelfall durch das oben genannte <b>Forschungsprivileg</b> (vgl. Art.
                        89 Abs. 2 i.V.m. § 27 Abs. 2 BDSG bzw. § 13 Abs. 4 LDSG BW) beschränkt sein, sofern die
                        Verwirklichung oder Erfüllung eines Forschungszwecks unmöglich gemacht oder ernsthaft beeinträchtigt
                        werden kann und die Beschränkung zur Erfüllung dieses Zweckes notwendig ist.
                    </p>
                    <p>
                        Daneben besteht ein Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DS-GVO), z.B. beim
                        Landesbeauftragten für Datenschutz und Informationsfreiheit Baden-Württemberg, Lautenschlagerstraße
                        20, 70173 Stuttgart, Tel. 0711/61 55 41 - 0, <a
                            href="mailto:poststelle@lfdi.bwl.de">poststelle@lfdi.bwl.de</a>
                    </p>

                    <h5>10. Änderungen</h5>
                    <p>Um grundsätzlich zu ermöglichen, dass diese Datenschutzerklärung aktuellen gesetzlichen Vorgaben bzw.
                        hier vorgehaltenen Leistungen oder deren technischen Vorgaben entspricht, behalten wir uns vor
                        entsprechende Änderungen vorzunehmen.</p>
                </b-card-body>
            </b-card>

        </div>
    </div>
</template>

<script>

import NavHeader from "../components/Nav_Header.vue"
import NavSidebar from "../components/Nav_Sidebar.vue"
export default {
    name: 'datenschutz',
    components: {
        NavHeader,
        NavSidebar,
    },

    data() {
        return {

        }
    }
}

</script>

<style lang="scss" scoped></style>
